@charset "UTF-8";
@import "~bootstrap/scss/bootstrap";
@import "bootstrap";

$color-red-dark: #EF4636;
$color-red-light: #FFA8A0;
$color-pink-light: #FFFFD0;
$color-pink-dark: #EEEEAA;
$color-white: #FFFFFF;
$color-gray-shadow: #AAAAAA;
$color-black: #000000;
$color-yellow-light: #FFFFDC;
$color-yellow-dark: #4D4D2C;

@counter-style single-emoji {
  system: cyclic;
  symbols: "\9670";
  suffix: " ";
}

ul {
  &.custom {
    list-style: none;
    padding-left: 20px;
  }

  &.custom li::before {
    content: "◆";
    color: #E50E32;
    margin-right: 10px;
  }
}

body {
  height: auto;
  color: black;
  font-family: "Montserrat", sans-serif;
  background-color: $color-yellow-light;

  article {
    padding: 75px 0 70px;

    @media screen and (max-width: 767px) {
      padding-bottom: 15px;
    }
  }

  section {
    &:not(.section-heading) {
      padding: 40px 0 70px;
      border-bottom: $color-yellow-dark 1px solid;
    }

    h1 {
      font-size: 58px;
      font-weight: 900;
      text-align: center;
      padding-top: 70px;
      margin-bottom: 1em !important;
    }

    h2 {
      font-size: 45px;
      font-weight: 700;
      text-align: center;
      padding-top: 55px;
      margin-bottom: .55em !important;
    }

    h3 {
      font-size: 35px;
      font-weight: 700;
      text-align: center;
    }
  }
}

nav {
  position: fixed !important;
  width: 100%;
  z-index: 2000;
  background-color: white;
  box-shadow: 0 -5px 5px 5px #333333;

  .navbar-brand img {
    height: 48px;
  }

  .nav-item {
    .nav-link {
      color: $color-black;
    }

    &:hover {
      font-weight: 500;
      text-decoration: none;

      .nav-link {
        padding-left: 7.5px;
        padding-right: 7.5px;
      }
    }
  }
}

.img-frame {
  border: white solid 15px;
  border-bottom-width: 35px;
}

.section-profits {
  .row-profits {
    .img-profits {
      width: 300px;
      text-align: center;
    }
  }

  .text-profits {
    p.fs-1::before {
      content: "◆";
      color: #E50E32;
      margin-right: 10px;
    }
  }
}

.section-program {
  .row-program {
    .img-program {
      border-radius: 50%;
      width: 300px;
      text-align: center;
    }

    .program-name {
      font-weight: 600;
      font-size: 28px;
    }

    .program-title {
      font-weight: 500;
      font-size: 20px;
    }
  }
}

.section-location {
  .row-staff {
    width: 80%;
    margin: auto;

    .img-staff {
      border-radius: 50%;
      width: 300px;
      text-align: center;
    }

    .staff-name {
      font-weight: 600;
      font-size: 36px;
    }

    .staff-title {
      font-weight: 500;
      font-size: 24px;
    }
  }
}

.section-signups {
  .signup-step-inner {
    background-color: $color-pink-dark;
    border-radius: 12px;
    box-shadow: 2px 2px 6px $color-gray-shadow, -2px -2px 6px $color-white;
    transition: box-shadow 0.15s ease;
    cursor: default;

    &:hover {
      box-shadow: 4px 4px 12px $color-gray-shadow, -4px -4px 12px $color-white;
    }
  }
}

.section-landing {
  padding: 100px 0 !important;

  .text {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
  }
}

footer {
  width: 100%;

  .bg-holder {
    width: 100%;

    &.data {
      padding: 20px 0;
      background-color: $color-pink-light;
    }

    &.copyright {
      background-color: $color-pink-dark;
    }
  }

  .row-footer-copyright {
    padding: 12px;
    font-weight: 400;
  }

  .footer {
    .name {
      font-weight: 800;
    }

    .row-footer-data {
      padding: 16px 0;

      a {
        text-decoration: none;
        color: inherit;
        font-weight: 600;
      }

      .social-icons {
        i {
          font-size: 28px;
        }
      }

      p {
        line-height: 20px;

        i {
          padding: 0 8px 0 0;
          font-size: 20px;
        }
      }

      .col-legal {
        .legal-heading, .account-heading {
          font-weight: 600;
        }

        .legal-data, .account-data {
          margin: 0;
        }

        .account-heading {
          margin-top: 24px;
        }

        .footer-link {
          display: block;
          margin: 8px 0;

          &:first-of-type {
            margin-top: 16px
          }
        }
      }
    }

    #map iframe {
      display: block;
      width: 90%;
      height: 200px;
      padding: 1px;
      margin-top: 20px;
    }
  }
}

// components

.btn-primary {
  @include button-variant($color-red-dark, darken($color-red-dark, 7.5%), $color-white, lighten($color-red-dark, 2%), lighten($color-red-dark, 5%), $color-white);
}

span.accent {
  font-weight: 600;
  color: $color-red-dark;
}

div.line {
  background-color: $color-red-light;
  height: 5px;
  width: 100px;
  margin: 0 auto 1em;
}

.star {
  color: $color-red-dark;
}

.div-header-content {
  position: relative;
  width: 100%;

  .heading-container {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 108px;

    .heading-text {
      background-color: rgba(155, 155, 155, 0.5);
      border-radius: 32px;
      padding: 12px 18px;
      text-align: center;
      max-width: 450px;
      max-height: 400px;
      margin: auto;
      color: black;
      text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;

      .text {
        font-size: 32px;

        @media (min-width: 768px) {
          font-size: 48px;
        }
      }
    }
  }
}

.signup-big {
  width: 100%;
  padding: 14px;
  font-size: 28px;
  font-weight: 600;
}

.contact-form,
.contact-error,
.contact-success {
  display: block;
  position: relative;
  height: 300px;
}
